import React from 'react';
import classNames from 'classnames';
import SpinnerCircle from './spinner-circle.svg';
import styles from './styles.module.scss';
export interface SpinnerProps {
  className?: string;
  text?: string;
  iconWidth?: number;
}
const Spinner = ({
  className,
  text,
  iconWidth = 24
}: SpinnerProps) => {
  return <div className={classNames(styles.spinnerWrapper, className)} data-sentry-component="Spinner" data-sentry-source-file="index.tsx">
      <SpinnerCircle style={{
      width: `${iconWidth}px`,
      height: `${iconWidth}px`
    }} className={styles.spinner} data-testid="spinner" data-sentry-element="SpinnerCircle" data-sentry-source-file="index.tsx" />
      {text ? <div className={styles.text} role="status" aria-live="polite">
          {text}
        </div> : <span className={styles.hiddenText} role="status" aria-live="polite">
          Loading...
        </span>}
    </div>;
};
export default Spinner;