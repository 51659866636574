import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  getSearchCriteriaFrom,
  toLandingPageUrl,
  toSearchPageUrl,
} from '../../../search/searchCriteria'
import {
  countryCodeForCountry,
  useCountryContext,
} from '../../../contexts/countryContext'
import requestStatus from '../../../constants/requestStatus'
import { formatPopularCityCode } from '../../../utils/search/helpers'

import HomePageSearchBarDesktop from './HomePageSearchBarDesktop'
import HomePageSearchBarMobile from './HomePageSearchBarMobile'

import styles from './styles.module.scss'

const HomePageSearchBar = ({
  className,
  autocompleteApi,
  geoCoordinatesApi,
  history,
  locations,
  countryCodeForAutocomplete,
}) => {
  const { country } = useCountryContext()

  const [searchCriteria, setSearchCriteria] = useState(
    getSearchCriteriaFrom({}, locations, country)
  )
  const [isRedirecting, setIsRedirecting] = useState(false)

  const [geoCoordinatesApiStatus, setGeoCoordinatesApiStatus] = useState(
    requestStatus.idle
  )
  const [locationFieldValue, setLocationFieldValue] = useState('')

  const isOnAfsLocationsList = (location) => {
    return !!locations.find((l) => l.possibleNames.includes(location))
  }

  const redirectToPageWith = (criteria) => {
    setIsRedirecting(true)

    if (isOnAfsLocationsList(criteria.location)) {
      const locationPath = locations.find((l) =>
        l.possibleNames.includes(criteria.location)
      ).linkPath

      history.push(
        toLandingPageUrl({
          locationPath,
        })
      )
    } else {
      history.push(toSearchPageUrl(criteria))
    }
  }

  const getGeoCoordinatesFor = (selection, onSuccessCallback) => {
    if (geoCoordinatesApiStatus === requestStatus.waiting) return

    if (!selection) {
      const nextSearchCriteria = searchCriteria.withLocation('')
      setSearchCriteria(nextSearchCriteria)
      return
    }

    setGeoCoordinatesApiStatus(requestStatus.waiting)

    const countryCode = countryCodeForCountry(selection.country)

    geoCoordinatesApi(countryCode, selection.placeId).then((response) => {
      if (response.success) {
        onSuccessCallback(selection, response)
        setGeoCoordinatesApiStatus(requestStatus.success)
      } else {
        setGeoCoordinatesApiStatus(requestStatus.error)
      }
    })
  }

  const locationNameWithoutCountry = (locationName) => {
    if (countryCodeForAutocomplete === 'afsCountries') {
      const locationCommaSeparatedParts = locationName.split(', ')

      return locationCommaSeparatedParts
        .slice(0, locationCommaSeparatedParts.length - 1)
        .join(', ')
    }

    return locationName
  }

  const normalisedLocationNameFrom = (location) => {
    return isOnAfsLocationsList(location.label.mainText)
      ? location.label.mainText
      : locationNameWithoutCountry(location.name)
  }

  const handleBlurLocation = (location, geoCoordinates) => {
    setSearchCriteria((prevState) => {
      const locationName = normalisedLocationNameFrom(location)
      const countryCode = countryCodeForCountry(location.country)

      return prevState
        .withLocation(locationName, geoCoordinates)
        .withCountryCode(countryCode)
    })
  }

  const handleSelectLocation = (location, geoCoordinates, closeOverlay) => {
    const locationName = normalisedLocationNameFrom(location)
    const countryCode = countryCodeForCountry(location.country)

    const nextSearchCriteria = searchCriteria
      .withLocation(locationName, geoCoordinates)
      .withCountryCode(countryCode)

    setSearchCriteria(nextSearchCriteria)

    if (closeOverlay) {
      closeOverlay()
    }

    redirectToPageWith(nextSearchCriteria)
  }

  const handleSelectLocationMobile = (closeOverlay) => {
    return (location, geoCoordinates) => {
      return handleSelectLocation(location, geoCoordinates, closeOverlay)
    }
  }

  const onChangeLocation = (value) => {
    setLocationFieldValue(value)
  }

  const onBlurLocation = (selection) => {
    const newSelectionObj = formatPopularCityCode(selection)

    const finalSelection =
      typeof newSelectionObj !== 'undefined' ? newSelectionObj : selection
    getGeoCoordinatesFor(finalSelection, handleBlurLocation)
  }

  const onSelectLocation = (selection) => {
    const newSelectionObj = formatPopularCityCode(selection)

    const finalSelection =
      typeof newSelectionObj !== 'undefined' ? newSelectionObj : selection

    getGeoCoordinatesFor(finalSelection, handleSelectLocation)
  }

  const onSelectLocationMobile = (selection, closeOverlay) => {
    const newSelectionObj = formatPopularCityCode(selection)

    const finalSelection =
      typeof newSelectionObj !== 'undefined' ? newSelectionObj : selection
    getGeoCoordinatesFor(
      finalSelection,
      handleSelectLocationMobile(closeOverlay)
    )
  }

  const isLoading =
    isRedirecting || geoCoordinatesApiStatus === requestStatus.waiting

  const showGeoCoordinatesError =
    geoCoordinatesApiStatus === requestStatus.error

  const clearGeoCoordinatesError = () => {
    if (geoCoordinatesApiStatus === requestStatus.error) {
      setGeoCoordinatesApiStatus(requestStatus.idle)
    }
  }

  return (
    <>
      <HomePageSearchBarMobile
        className={classNames(styles.searchBarMobile, className)}
        autocompleteApi={autocompleteApi}
        countryCodeForAutocomplete={countryCodeForAutocomplete}
        handleBlurLocation={onBlurLocation}
        handleSelectLocation={onSelectLocationMobile}
        location={locationFieldValue}
        handleChangeLocation={onChangeLocation}
        isLoading={isLoading}
        isRedirecting={isRedirecting}
        clearGeoCoordinatesError={clearGeoCoordinatesError}
        showGeoCoordinatesError={showGeoCoordinatesError}
      />

      <HomePageSearchBarDesktop
        className={classNames(styles.searchBarDesktop, className)}
        autocompleteApi={autocompleteApi}
        countryCodeForAutocomplete={countryCodeForAutocomplete}
        onBlurLocation={onBlurLocation}
        onSelectLocation={onSelectLocation}
        location={locationFieldValue}
        onChangeLocation={onChangeLocation}
        isLoading={isLoading}
        isRedirecting={isRedirecting}
        showGeoCoordinatesError={showGeoCoordinatesError}
        clearGeoCoordinatesError={clearGeoCoordinatesError}
        selectedLocation={searchCriteria.location}
      />
    </>
  )
}

HomePageSearchBar.propTypes = {
  className: PropTypes.string,
  autocompleteApi: PropTypes.func.isRequired,
  geoCoordinatesApi: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      possibleNames: PropTypes.arrayOf(PropTypes.string).isRequired,
      linkPath: PropTypes.string.isRequired,
    })
  ).isRequired,
  countryCodeForAutocomplete: PropTypes.oneOf([
    'afsCountries',
    'gb',
    'ie',
    'au',
  ]).isRequired,
}

export default HomePageSearchBar
