import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import LocationAutocompleteField, {
  LocationAutocompleteVariant,
} from '../../../molecules/LocationAutocompleteField'
import ClearButton from '../../../atoms/ClearButton'

import LoadingIcon from './svgs/redirecting-icon.svg'
import SearchIcon from './svgs/search-icon.svg'

import styles from './styles.module.scss'

const HomePageSearchBarDesktop = ({
  className,
  autocompleteApi,
  countryCodeForAutocomplete,
  onBlurLocation,
  onSelectLocation,
  location,
  onChangeLocation,
  isLoading,
  isRedirecting,
  clearGeoCoordinatesError,
  showGeoCoordinatesError,
  selectedLocation,
}) => {
  const inputRef = useRef(null)

  const onFormSubmit = (event) => {
    event.preventDefault()

    if (!selectedLocation) {
      inputRef.current.focus()
      return
    }

    onSelectLocation(selectedLocation)
  }

  return (
    <form
      className={classNames(styles.form, className)}
      onSubmit={onFormSubmit}
    >
      <LocationAutocompleteField
        variant={LocationAutocompleteVariant.HPDESKTOP}
        isDesktop={true}
        name="location"
        id="location-desktop"
        ref={inputRef}
        initialValue={location}
        autocompleteApi={autocompleteApi}
        countryCodeForAutocomplete={countryCodeForAutocomplete}
        clearGeoCoordinatesError={clearGeoCoordinatesError}
        showGeoCoordinatesError={showGeoCoordinatesError}
        handleOnChange={onChangeLocation}
        handleOnBlur={onBlurLocation}
        handleOnSelect={onSelectLocation}
        renderLabel={(props) => (
          <label className={styles.label} {...props}>
            Location
          </label>
        )}
        renderInputWrapper={({ ref, children, ...props }) => {
          return (
            <div ref={ref} {...props} className={styles.inputWrapper}>
              {children}
            </div>
          )
        }}
        renderInput={({ ref, ...props }) => (
          <input
            ref={ref}
            {...props}
            className={classNames(styles.input, {
              [styles.inputEmpty]: !selectedLocation,
            })}
            placeholder="Search by city or university"
          />
        )}
        renderClearButton={(props) => (
          <ClearButton {...props} className={styles.clearButton} />
        )}
        renderMenuContainer={({ children, ...props }) => (
          <Fragment {...props}>{children}</Fragment>
        )}
      />
      <button
        className={classNames(styles.buttonWrapper, {
          [styles.buttonWrapperDisabled]: isLoading,
        })}
        type="submit"
        data-testid="search-button"
        aria-label="Search"
        disabled={isLoading}
        aria-disabled={isLoading}
      >
        <div className={styles.buttonInner}>
          {isRedirecting ? (
            <LoadingIcon
              className={styles.loadingIcon}
              data-testid="loading-icon"
            />
          ) : (
            <SearchIcon
              className={styles.searchIcon}
              data-testid="search-icon"
            />
          )}
        </div>
      </button>
    </form>
  )
}

HomePageSearchBarDesktop.propTypes = {
  className: PropTypes.string,
  selectedLocation: PropTypes.string.isRequired,
  autocompleteApi: PropTypes.func.isRequired,
  countryCodeForAutocomplete: PropTypes.oneOf([
    'afsCountries',
    'gb',
    'ie',
    'au',
  ]).isRequired,
  clearGeoCoordinatesError: PropTypes.func.isRequired,
  showGeoCoordinatesError: PropTypes.bool.isRequired,
  onChangeLocation: PropTypes.func.isRequired,
  onBlurLocation: PropTypes.func.isRequired,
  onSelectLocation: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRedirecting: PropTypes.bool.isRequired,
}

export default HomePageSearchBarDesktop
