import React, { DetailedHTMLProps, LabelHTMLAttributes, LegacyRef, MutableRefObject, ReactNode } from 'react';
import classNames from 'classnames';
import LocationAutocompleteField, { LocationAutocompleteVariant } from '../../molecules/LocationAutocompleteField';
import ClearButton from '../../atoms/ClearButton';
import Svg from '../../atoms/Svg';
import CountryCode from '../../../types/CountryCode';
import Spinner from '../../atoms/Spinner';
import styles from './styles.module.scss';
interface LocationMenuMobileProps {
  id: string;
  isOverlayOpen: boolean;
  handleOverlayClose: () => void;
  location: string;
  handleBlurLocation: () => void;
  handleSelectLocation: () => void;
  handleChangeLocation?: (arg: string) => void;
  autocompleteApi: () => void;
  countryCodeForAutocomplete: CountryCode;
  handleSetCountry?: () => void;
  showGeoCoordinatesError: boolean;
  clearGeoCoordinatesError: () => void;
  isLoading?: boolean;
}
const LocationMenuMobile = ({
  id,
  handleOverlayClose,
  isOverlayOpen,
  location,
  handleSelectLocation,
  handleBlurLocation,
  handleChangeLocation,
  autocompleteApi,
  countryCodeForAutocomplete,
  handleSetCountry,
  showGeoCoordinatesError,
  clearGeoCoordinatesError,
  isLoading
}: LocationMenuMobileProps) => {
  const handleClose = () => {
    if (handleChangeLocation) {
      handleChangeLocation(location);
    }
    if (handleOverlayClose) {
      handleOverlayClose();
    }
  };
  return <div id={id} className={classNames(styles.menu, {
    [styles.menuOpen]: isOverlayOpen
  })} aria-hidden={!isOverlayOpen} data-testid={id} data-sentry-component="LocationMenuMobile" data-sentry-source-file="index.tsx">
      <div className={styles.header}>
        <button className={styles.backButton} onClick={handleClose} aria-label="Close menu" aria-controls={id}>
          <Svg type="utility" name="back-arrow" className={styles.backIcon} data-sentry-element="Svg" data-sentry-source-file="index.tsx" />
        </button>
        <strong className={styles.heading} data-testid="menu-heading">
          Location
        </strong>
      </div>

      {isOverlayOpen ? <LocationAutocompleteField variant={LocationAutocompleteVariant.HPMOBILE} isDesktop={false} name="location" id="location-mobile" initialValue={location} handleOnBlur={handleBlurLocation} handleOnSelect={handleSelectLocation} showGeoCoordinatesError={showGeoCoordinatesError} clearGeoCoordinatesError={clearGeoCoordinatesError} autocompleteApi={autocompleteApi} countryCodeForAutocomplete={countryCodeForAutocomplete} setCountry={handleSetCountry} autoFocus renderLabel={(props: DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>) => <label {...props} className={styles.label}>
              Location
            </label>} renderInputWrapper={({
      ref,
      children,
      ...props
    }: {
      ref: LegacyRef<HTMLDivElement> | undefined;
      children: ReactNode;
    }) => <div ref={ref} {...props} className={styles.inputWrapper}>
              {children}
            </div>} renderInput={({
      ref,
      ...props
    }: {
      ref: MutableRefObject<HTMLInputElement> | undefined;
    }) => <input ref={ref} {...props} className={styles.input} placeholder="Search by city or university" />} renderClearButton={(props: HTMLButtonElement) => {
      return isLoading ? <Spinner className={styles.loadingIcon} /> : <ClearButton {...props} className={styles.clearButton} />;
    }} renderMenuContainer={({
      children
    }: {
      children: ReactNode;
    }) => <>{children}</>} /> : null}
    </div>;
};
export default LocationMenuMobile;