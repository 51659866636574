import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import useBodyLockWhenOverlayIsActive from '../../../../hooks/useBodyLockWhenOverlayIsActive'

import LocationMenuMobile from '../../LocationMenuMobile'

import SearchIcon from './svgs/search-icon-mobile.svg'
import LoadingIcon from './svgs/loading-icon-mobile.svg'

import styles from './styles.module.scss'

const HomePageSearchBarMobile = ({
  className,
  handleBlurLocation,
  handleSelectLocation,
  location,
  handleChangeLocation,
  autocompleteApi,
  countryCodeForAutocomplete,
  clearGeoCoordinatesError,
  showGeoCoordinatesError,
  isLoading,
  isRedirecting,
}) => {
  const [isOverlayOpen, setIsOverLayOpen] = useState(false)
  useBodyLockWhenOverlayIsActive({
    isActive: isOverlayOpen,
    overlayUniqueId: 'home-page-search-bar-mobile',
  })

  const handleOverLayOpen = () => {
    setIsOverLayOpen(true)
  }

  const handleOverlayClose = () => {
    setIsOverLayOpen(false)
  }

  return (
    <div className={className}>
      <button
        className={classNames(styles.button, {
          [styles.buttonLoading]: isLoading,
        })}
        aria-haspopup="true"
        aria-expanded={isOverlayOpen}
        aria-controls="location-mobile"
        data-testid="search-mobile"
        onClick={handleOverLayOpen}
        disabled={isLoading}
        aria-disabled={isLoading}
      >
        <SearchIcon className={styles.searchIcon} />

        <span
          className={classNames(styles.buttonText, {
            [styles.buttonTextLoading]: location,
          })}
        >
          {location || 'Search by city or university'}
        </span>

        {isRedirecting ? (
          <LoadingIcon
            className={styles.loadingIcon}
            data-testid="loading-icon"
          />
        ) : null}
      </button>
      <LocationMenuMobile
        id="location-mobile"
        handleOverlayClose={handleOverlayClose}
        isOverlayOpen={isOverlayOpen || isLoading}
        location={location}
        handleSelectLocation={(selection) => {
          handleSelectLocation(selection, handleOverlayClose)
        }}
        handleBlurLocation={handleBlurLocation}
        handleChangeLocation={handleChangeLocation}
        autocompleteApi={autocompleteApi}
        countryCodeForAutocomplete={countryCodeForAutocomplete}
        showGeoCoordinatesError={showGeoCoordinatesError}
        clearGeoCoordinatesError={clearGeoCoordinatesError}
        isLoading={isLoading}
      />
    </div>
  )
}

HomePageSearchBarMobile.propTypes = {
  className: PropTypes.string,
  handleBlurLocation: PropTypes.func.isRequired,
  handleSelectLocation: PropTypes.func.isRequired,
  location: PropTypes.string.isRequired,
  handleChangeLocation: PropTypes.func.isRequired,
  autocompleteApi: PropTypes.func.isRequired,
  countryCodeForAutocomplete: PropTypes.oneOf([
    'afsCountries',
    'gb',
    'ie',
    'au',
  ]).isRequired,
  clearGeoCoordinatesError: PropTypes.func.isRequired,
  showGeoCoordinatesError: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isRedirecting: PropTypes.bool.isRequired,
}

export default HomePageSearchBarMobile
